import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const CantuReview = () => (
  <Layout>
    <SEO
      title="Cantu Review"
      description="Finally, everything you need to know about Cantu Beauty and their hair products. Is Cantu Curling Cream and other products good or bad for you?"
    />
    <h1>Cantu Beauty Review</h1>
    <h2 style={{color:"Orchid"}}>Everything you need to know about Cantu Beauty and their hair products</h2>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/Yiuq3Qq.jpg"
        alt="natural afro hair care cantu review"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@introspectivedsgn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Erik Mclean</a></p>
    </div>

    {/* <h2>Subtitle</h2> */}

    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}


    <p>Cantu is described as a multi-cultural hair care, its headquarters are in Dallas, Texas and it was founded in 2003. It was acquired and is owned by <strong>PDC Brands</strong> in July 2015. <a
        href="https://www.crunchbase.com/organization/pdc-brands"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
       PDC Brands
      </a> is an organisation that specialises in beauty and wellness products for both men and women. PDC Brands also owns <strong>Original Additions</strong> (a beauty brand) and <strong>Bodycology</strong> (bath products).
    </p>
    
    <p style={{color:"steelBlue", fontWeight: "600"}}><a
        href="#products"
        style={{ textDecoration: "none" }}
      >
      Click here to skip ahead to products
      </a></p>

    <p>Cantu is one of the most well know brand names in the black and curly hair community. They are also one of the most polarising with many of us in love with their products and others hating them. This article will answer all of your questions about Cantu and their products. What ingredients do they use and are they safe? Should you use their products and if so, which Cantu products should you use and which ones can you use together?</p>

    <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

    <h2>The Problem With Cantu</h2>
    <p>There is this problem in the beauty products for the black community industry where a lot of big brands that we know aren't owned by black people. There are two main ways this can happen; 1 - a brand that was previously black owned has been sold to non-white owners. 2 - the brand was started by white owners to begin with.</p>
    <p>I'm sure we can all understand that it doesn't take a black CEO to run a company that makes good black hair products. The CEO's job is to make important decisions and I'm sure one decision that they all make is to hire experts in black hair. These people can be any ethnicity.</p>
    <p>The problem is when the owners know this can be an issue and appear to be hiding from the public and making it appear as though the company is black owned. Cantu is one of the companies that is white owned. They also have many black employees who hold low and high positions that have various levels of control in the company making it one of the most popular afro hair brands today.</p>

    <h2>The Second Problem</h2>
    <p>The other main problem that is many people have with Cantu is that they say the products dry out their hair due to the ingredients they use. We'll look at ingredients later on in this article. All hair products work in a different way for every person. Your hair will not agree with every product. This is probably the number one problem in the natural hair community. Moisture control.</p>
    <p>Not only do you have to find products that help keep moisture in your hair but you also have to find products that work year round. And products that work with the other products you have. It is a huge maze and is the reason we started this site in the first place. To solve that very problem.</p>
    <p>That being said, this argument is one that is used against every products. For every product you can find someone that loves it, you will find another that hates it. That's why you have to find what works for you. It can be a long and painful process.</p>

    <h2>Cantu for Curls</h2>
    <p>Or in other words, who is Cantu for? The Cantu product range is for various hair types. Their website categorises them in Curly, Coily, Wavy and Straightened. So Cantu products are not just aimed at natural hair types or curls, some are designed to work on treated hair too.</p>

    <h2>The Cantu Product Range</h2>
    <p>Cantu has many products, some familiar and some new, they also have an accessory range. Just like any manufacturer with many products, it's important to refresh and introduce new products when the market calls for it. This is why they have introduced the new Flaxseed and Avocado Collection. They also have a range dedicated to Men, one for kids and another for Hypoallergenic.</p>
    <p>I mentioned familiar products and these are the ones you can spot that have been sold for years. The Cantu Classics range with the orange and white bottles. The Natural hair collection with the orange and yellow paint type pattern on the bottles and containers.</p>
    <p>There are so many products on Cantu's range, let's lookout some of their most popular.</p>

    <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}><i>As an Amazon Associate I earn from qualifying purchases. Some of the links in this article may be affiliate links, which means I may receive a small commission at no additional cost to you if you decide to purchase something.</i></p>

    <h2 id="products">Cantu Hair Products</h2>
    <p>The two products that come up first when we search for Cantu leave-in conditioner are the leave-in conditioning cream for natural hair and the leave-in conditioning repair cream. This is similar for all the Cantu products so I'll leave this note here. We mentioned above how Cantu has different products ranges and this is what separates the two products. The Natural hair variant is described as having no sulphates, silicones, parabens or mineral oils. See the ingredients section below for the breakdown of each.</p>
    <p>Since we're all about Natural hair, let's look at the for Natural Hair range.</p>

    <h3>Cantu Leave in conditioner</h3>
    <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/Cantu-Butter-Natural-Conditioning-Repair/dp/B00F6BCNQ2/ref=as_li_ss_il?crid=2CEY0DQJ7J590&dchild=1&keywords=cantu+leave+in+conditioner&qid=1601463088&sprefix=cantu+leave+in+,aps,233&sr=8-6&linkCode=li3&tag=natural032d-20&linkId=181265ac6f4c5203da9b06ca9154eda5&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00F6BCNQ2&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B00F6BCNQ2" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    <p style={{color:"Orchid", fontWeight: "600"}}>A leave in conditioner is supposed to be applied after washing, alongside any other products you choose to add to your hair. Good ones can also be added on days between washes to help maintain moisture in your hair.</p>
    <p><a
        href="https://www.amazon.com/Cantu-Butter-Natural-Conditioning-Repair/dp/B00F6BCNQ2/ref=as_li_ss_tl?crid=2CEY0DQJ7J590&dchild=1&keywords=cantu+leave+in+conditioner&qid=1601463088&sprefix=cantu+leave+in+,aps,233&sr=8-6&linkCode=ll1&tag=natural032d-20&linkId=0b459f340c8aa948349e7d513aa547d8&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Check Price of Cantu Leave in conditioner
      </a></p>
    <p>Cantu leave-in conditioning cream is a repair and maintenance product. It helps repair hair damaged from heat, dryness, breakage and it gives the hair a softer texture to help prevent these things in the future. It can be quite heavy so if your hair needs lots of moisture, this will be great for you.</p>
    <p>Strangely, it can be used for lots of hair types. Whether you use it or not depends more on how your hair absorbs moisture. To use it for the first time, you should apply to towel dried hair. Then for maintenance you can apply smaller amounts as you need to.</p>
    <p>The product can be hard to get used to at first because it is a bit heavier than other products but when you figure it out, it can be a great addition or replacement for other products you might already use. A common tip is to try warming up the conditioner if you have low porosity hair. You can also try a heat cap to help the conditioner sink in.</p>
    

    <h3>Cantu Curl Activator</h3>
    <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/gp/product/B01LTIAU7Y/ref=as_li_ss_il?ie=UTF8&linkCode=li3&tag=natural032d-20&linkId=f046ad23d0ea4b3a0ccc5fac00ebe054&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LTIAU7Y&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B01LTIAU7Y" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    <p style={{color:"Orchid", fontWeight: "600"}}>Curl activators are supposed to enhance the natural curls in your hair and make them more defined by eliminating frizz. This gets harder as your curl pattern gets tighter. You might even find that curl activators don't work for you at all if you have really tight coils.</p>
    <p><a
        href="https://www.amazon.com/gp/product/B01LTIAU7Y/ref=as_li_ss_tl?ie=UTF8&linkCode=ll1&tag=natural032d-20&linkId=1397e483d8db880de49c592f5f87f2a7&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Check Price of Cantu Curl Activator
      </a></p>
    <p>Cantu Moisturizing Curl Activator cream is enhances the natural curl pattern of the hair and reduces dryness and frizz. This product is meant to be used for when you want to wear your hair out and make sure the curls stay moisturised throughout the day.</p>
    <p>You would apply the product after a wash and can either towel dry or do a wash and go if your hair can cope with that. This product is often recommended for hair growth and it is important to have a good moisture control routine. I mean, use a shampoo that doesn't strip moisture from the hair and use it alongside natural oils to help seal your hair.</p>
    <p>The curl activator can be used for all type 2, 3 and 4 natural hair, all ages, different porosities and all different ethnicities. It is a very versatile product. I just wouldn't recommend it for thin hair but if you are curious, search some tutorials of your own hair type on YouTube.</p>

    <h3>Cantu Curling Cream</h3>
    <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/Cantu-Butter-Natural-Coconut-Curling/dp/B008D5I61Y/ref=as_li_ss_il?dchild=1&keywords=cantu+curling+cream&qid=1601460477&sr=8-5&linkCode=li3&tag=natural032d-20&linkId=98615fc40a33ac7ff017827fc9c31463&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B008D5I61Y&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B008D5I61Y" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    <p style={{color:"Orchid", fontWeight: "600"}}>Similar to Curl Activators but meant to be much lighter and less greasy. It will also only bring out curls that you already have by helping to define them. If curling creams work for your hair, you can have a great experience with them.</p>
    <p><a
        href="https://www.amazon.com/Cantu-Butter-Natural-Coconut-Curling/dp/B008D5I61Y/ref=as_li_ss_tl?dchild=1&keywords=cantu+curling+cream&qid=1601460477&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=38420337d6c899f43b2e5287c727a899&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Check Price of Cantu Curling Cream
      </a></p>
    <p>The curling cream works on all hair including relaxed hair, 2, 3C, 4A and more. In other words, you need some curls to begin with. It is very light and much less greasy than alternatives which is what separates it from the curl activator.</p>
    <p>It is moisturising so it can replace your current leave in conditioner. It should be used after a wash or for maintenance on other days just like other leave in conditioners. If you find that it isn't helping you keep as much moisture as you want because your hair is very absorbent, you can try using oils for that extra help.</p>
    <p>It adds a visible shine to the hair and can actually eliminate the need to use a heat cap to lock in moisture. Again this all depends on the porosity and type of your hair so you will have to experiment and see what works for you.</p>

    <h3>Cantu Shampoo</h3>
    <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/Cantu-Natural-Sulfate-Free-Cleansing-Shampoo/dp/B07NNPQW73/ref=as_li_ss_il?dchild=1&keywords=cantu+shampoo&qid=1601464148&sr=8-5&linkCode=li3&tag=natural032d-20&linkId=fe4f9a14c5a6f18d06c0278463e20cb6&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07NNPQW73&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B07NNPQW73" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    <p style={{color:"Orchid", fontWeight: "600"}}>Shampoos are meant to clean your hair on wash days and the good ones do this without stopping all the natural oils from your hair. It's seems simple but can be one of the most important products to choose. A good shampoo can make all the difference to your routine and finished result.</p>
    <p><a
        href="https://www.amazon.com/Cantu-Natural-Sulfate-Free-Cleansing-Shampoo/dp/B07NNPQW73/ref=as_li_ss_tl?dchild=1&keywords=cantu+shampoo&qid=1601464148&sr=8-5&linkCode=ll1&tag=natural032d-20&linkId=0670e0f4451db1d7e0d767ae1c2443d6&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Check Price of Cantu Shampoo
      </a></p>
    <p>Cantu Cleansing Cream Shampoo is meant for natural and coloured hair. It does lather which helps break down the grease and leave your hair clean and feeling clean. There isn't much to say about this one, it does the job really well. Look out for the smaller bottle because the big one doesn't have as much in it as you would think.</p>

    <h2>What Cantu Products to Use Together</h2>
    <p>It can be hard to tell sometimes what products can be used together and which ones can't. If you get it wrong, you can end up with hair that dries out too quickly or hair that feels greasy with not much benefit to it at all.</p>
    <p>Cantu has already done the hard work by offering bundles or sets that can be bought together. If you have already bought 1 or 2 in a set and just need the final product, you can look up the set and find what completes it. Then buy it separately. Or if you are just starting out with Cantu, you could buy the set to start with.</p>
    <p><a
        href="https://www.amazon.com/Cantu-Shampoo-Hydrating-Conditioner-Activator/dp/B075JX5WHK/ref=as_li_ss_tl?dchild=1&keywords=cantu+set&qid=1601483391&sr=8-3&linkCode=ll1&tag=natural032d-20&linkId=eac6bdda74daa9f9275291fd1c541c3a&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Cantu Set
      </a></p>
      <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/Cantu-Shampoo-Hydrating-Conditioner-Activator/dp/B075JX5WHK/ref=as_li_ss_il?dchild=1&keywords=cantu+set&qid=1601483391&sr=8-3&linkCode=li3&tag=natural032d-20&linkId=40f29dbe4b38714be3c6b9d73afb9f8b&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B075JX5WHK&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B075JX5WHK" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    

    <h2>Cantu for Men</h2>
    <p>Hair care in the black community if often directed at women and can be confusing for any men that also want to grow their hair. I'll just say this: These products work for both Men and Women. The only thing I would say to watch out for if it's not your thing is sweet or flowery smelling products. The smells are usually pleasant and nothing I would ever describe as feminine.</p>
    <p>So please just buy what you want to and don't worry about it being specifically for men or women. Cantu does have a men's range so if you are worried about taking product from a feminine bottle, go for one of those.</p>
    <p><a
        href="https://www.amazon.com/Cantu-Butter-Collection-Leave-Conditioner/dp/B01LTIAU8S/ref=as_li_ss_tl?dchild=1&keywords=cantu&qid=1601483180&sr=8-25&linkCode=ll1&tag=natural032d-20&linkId=7c558d054cb9afb3ed6986528b9eb3b5&language=en_US"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Shop Cantu for men
      </a></p>
      <div style={{textAlign:"center"}}>
    <a href="https://www.amazon.com/Cantu-Butter-Collection-Leave-Conditioner/dp/B01LTIAU8S/ref=as_li_ss_il?dchild=1&keywords=cantu&qid=1601483180&sr=8-25&linkCode=li3&tag=natural032d-20&linkId=93253b0dd3396399334ba5af3dbe50ef&language=en_US" target="_blank" rel="noopener noreferrer nofollow"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LTIAU8S&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=natural032d-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=natural032d-20&language=en_US&l=li3&o=1&a=B01LTIAU8S" width="1" height="1" border="0" alt="" style={{border: "none !important", margin: "0px !important"}} />
    </div>
    

    <h2>Cantu Ingredients</h2>
    <p>Or lack of. Cantu is aware of the problems some of us experience with certain ingredients. One of their solutions was to create the Natural Hair product range. The ingredients to watch out for are sulphates, silicones, parabens and mineral oils. The natural hair range doesn't contain any of these. So if you are asking; "is Cantu good or bad for your hair?", let's have a look at what each of these ingredients does.</p>
    <p>If you are worried about any of these ingredients, try cutting them from your routine for a bit to see if you get any benefits. The downside to a lot of claims in our community is that they are rumours and there are usually no scientific studies to point to. It's just people saying what they believe to be true. I hope there are studies that can clear this up once and for all. Until then, I'll say what is commonly discussed in the community.</p>

    <h3 style={{color:"Orchid"}}>Sulphates</h3>
    <p>Used to help clean the hair and scalp. They help remove oils but the problem is when they can remove our natural oils too and without proper moisture control , our hair and scalp can become dry. Some claim that they also can strip protein from the hair leaving it weaker and prone to damage.</p>

    <h3 style={{color:"Orchid"}}>Silicones</h3>
    <p>Silicones cost the hair and are mean to help with moisture and shine. They can do the opposite by blocking moisture and stopping it reaching the hair and scalp. Essentially making a barrier where and new moisture from other products and water cannot be effective. I'm sure you've heard that hair can look moisturised but actually be dry. Well silicones are often to blame.</p>
    <p>On the other hand, silicones can be safe and have soothing effects. They protect the hair from damage and reduce frizz. This is because they are not easily absorbed. Some blame them for irritation of the scalp and others say they reduce it.</p>

    <h3 style={{color:"Orchid"}}>Parabens</h3>
    <p>Used as preservatives to help products last. They are one of the least potent used in the industry. They are easy to remove by rinsing the hair and aren't known to cause any build up. They are relatively invisible to our hair and scalp. We need some sort of preservatives in hair products to give them any sort of shelf life and paragons seem to be one of the least bad of a non-natural bunch.</p>

    <h3 style={{color:"Orchid"}}>Mineral Oil</h3>
    <p>Added to hair products to create the shine and provide a moisturising effect. It is used in most beauty products because of its availability and effectiveness. It is colourless and odourless. The harm comes from the build-up they are said to cause. Just like Silicones, they can sit on the scalp and hair and bock real moisture from passing. This can result in hair ending up dry and damaged.</p>
    <p>If you want to use products that contain mineral oil. Avoid build up by washing your hair and looking after it as you normally would. Everyone will have a moisture control routine so you need to find your own.</p>

    <h2>Should You Use Cantu Products?</h2>
    <p>We recommend Cantu products and if you are trying to avoid the ingredients we mentioned above, go for the Natural Hair range.</p>



    <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>                  
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default CantuReview
